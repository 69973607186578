import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { SessionService } from '../services/session.service';
import { TokenService } from '../components/auth/token.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(
		private tokenService: TokenService,
		private sessionService: SessionService
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		//Dont pass the trb auth headers when calling s3 upload api
		if (request.url.includes('amazonaws.com')) {
			const urlObj = new URL(request.url);
			const securityToken = urlObj.searchParams.get('x-amz-security-token') || urlObj.searchParams.get('X-Amz-Security-Token');

			// Check if the 'x-amz-security-token' parameter is present
			if (securityToken) {
				return next.handle(request);
			} else {
				console.log('aws token parameter not found in the URL');
			}
		} else if (request.url.includes('quicksight.aws.amazon.com')) {
			return next.handle(request);
		}

		return this.tokenService.acquireToken().pipe(
			mergeMap((token) => {
				if (this.sessionService.sessionGUID) {
					const modifiedRequest = request.clone({
						headers: request.headers.set('Authorization', `Bearer ${token}`).set('Session', this.sessionService.sessionGUID).set('ModUrl', window.location.href),
					});
					return next.handle(modifiedRequest);
				} else {
					const modifiedRequest = request.clone({
						headers: request.headers.set('Authorization', `Bearer ${token}`),
					});
					return next.handle(modifiedRequest);
				}
			})
		);
	}
}
